<template>
  <div class="download">
    <a href="#" @click.prevent="download">{{ label }}</a>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    user: {
      type: String,
      require: false,
    },
    label: {
      type: String,
      require: true,
    },
    uriDownload: {
      type: String,
      require: true,
    },
  },
  methods: {
    download() {
      this.$api()
        .get(this.uriDownload, {
          responseType: "blob",
          params: {
            path: this.file.path,
            nomeDeUsuario: this.user,
          },
        })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.file.path);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          alert("Não conseguimos realizar o download do arquivo");
        });
    },
  },
};
</script>
